/*
Mixins, functions & helpers
you need this at the head of every file that uses them
*/
/*
This imports the Guardian colour pallette from pasteup as a sass map.
 You can see the palette at support-ui.gutools.co.uk
*/
.is-hidden,
[hidden] {
  display: none !important;
}

.show {
  display: block !important;
}

.component-button {
  font-size: 16px;
  line-height: 22px;
  font-family: "GuardianTextSans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  height: 42px;
  min-height: 42px;
  padding: 0 21px;
  border: none;
  border-radius: 21px;
  box-sizing: border-box;
  background: transparent;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  justify-content: space-between;
  position: relative;
}
.component-button:hover, .component-button:focus {
  outline: 0;
}
.component-button[data-disabled] {
  pointer-events: none;
}

.component-button--non-interactive {
  pointer-events: none;
}

.component-button--primary {
  background-color: #ffe500;
  color: #121212;
}
.component-button--primary:hover, .component-button--primary:focus {
  background-color: #ffbb50;
}

.component-button--secondary {
  color: #ffffff;
  background-color: #333333;
}
.component-button--secondary:hover, .component-button--secondary:focus {
  background-color: #121212;
}

.component-button--tertiary {
  color: #333333;
  background-color: transparent;
  border: solid 1px #333333;
}
.component-button--tertiary:hover, .component-button--tertiary:focus {
  background-color: #f6f6f6;
}

.component-button--tertiaryFeature {
  color: #ffe500;
  background-color: transparent;
  border: solid 1.5px #ffe500;
}

.component-button--green {
  color: #ffffff;
  background-color: #22874D;
}
.component-button--green:hover, .component-button--green:focus {
  background-color: #185E36;
}

.component-button--blue {
  color: #052962;
  background-color: #c1d8fc;
}
.component-button--blue:hover, .component-button--blue:focus {
  color: #fff;
  background-color: #4e77bd;
}

.component-button--greenHollow {
  color: #22874D;
  border: 1px solid #dcdcdc;
}
.component-button--greenHollow:hover, .component-button--greenHollow:focus {
  background: #f6f6f6;
}

.component-button--greyHollow {
  color: #121212;
  border: 1px solid #dcdcdc;
}
.component-button--greyHollow:hover, .component-button--greyHollow:focus {
  background: #f6f6f6;
}

.component-button--disabled,
.component-button[disabled],
.component-button[data-disabled] {
  color: #999999;
  background-color: #ededed;
  border: 1px solid #dcdcdc;
  cursor: not-allowed;
}

.component-button__content,
.component-button svg {
  flex: 0 0 auto;
  display: block;
}

.component-button svg {
  fill: currentColor;
  position: relative;
  width: 21px;
  height: auto;
}

.component-button--hasicon-left {
  flex-direction: row-reverse;
}
.component-button--hasicon-left svg {
  margin: 0 10.5px 0 -5.25px;
}

.component-button--hasicon-right svg {
  margin: 0 -5.25px 0 10.5px;
}

/* icon-specific animations & transitions */
.component-button .svg-arrow-right-straight {
  transition: 0.3s ease-in-out transform;
  will-change: transform;
}
.component-button:hover .svg-arrow-right-straight, .component-button:focus .svg-arrow-right-straight {
  transform: translateX(20%);
}
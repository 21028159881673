/*
Mixins, functions & helpers
you need this at the head of every file that uses them
*/
/*
This imports the Guardian colour pallette from pasteup as a sass map.
 You can see the palette at support-ui.gutools.co.uk
*/
.is-hidden,
[hidden] {
  display: none !important;
}

.show {
  display: block !important;
}

.component-text {
  font-size: 17px;
  line-height: 24px;
  font-family: "GuardianTextEgyptian", Georgia, serif;
  font-weight: 400;
  overflow-wrap: break-word;
}
.component-text > *:not(:first-child) {
  margin-top: 12px;
}
.component-text > .component-text__heading + p {
  margin-top: -6px;
}

.component-text + .component-text {
  margin-top: -12px;
}

.component-text code {
  font-family: monospace;
  background: #ededed;
  color: #121212;
  padding: 3px 5px;
}

.component-text a {
  color: inherit;
}

.component-text__heading {
  font-size: 22px;
  line-height: 26px;
  font-family: "GH Guardian Headline", Georgia, serif;
  font-weight: 700;
  margin-bottom: 12px;
}
@media (min-width: 46.25em) {
  .component-text__heading {
    font-size: 24px;
    line-height: 28px;
  }
}

.component-text__callout {
  font-size: 22px;
  line-height: 26px;
  font-family: "GH Guardian Headline", Georgia, serif;
  font-weight: 700;
}
@media (min-width: 46.25em) {
  .component-text__callout {
    font-size: 24px;
    line-height: 28px;
  }
}
.component-text__callout > strong {
  padding: 0 5px;
  background-color: #ffe500;
  color: #121212;
}

.component-text__large {
  font-size: 22px;
  line-height: 26px;
  font-family: "GH Guardian Headline", Georgia, serif;
  font-weight: 700;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}
@media (min-width: 46.25em) {
  .component-text__large {
    font-size: 24px;
    line-height: 28px;
  }
}
@media (min-width: 46.25em) {
  .component-text__large {
    font-size: 20px;
    line-height: 24px;
    font-family: "GH Guardian Headline", Georgia, serif;
    font-weight: 300;
  }
}
@media (min-width: 46.25em) and (min-width: 46.25em) {
  .component-text__large {
    font-size: 22px;
    line-height: 26px;
  }
}

.component-text__sans {
  font-size: 16px;
  line-height: 22px;
  font-family: "GuardianTextSans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
}

.component-text__paddingTop {
  padding-top: 12px;
}
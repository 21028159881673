/*
Mixins, functions & helpers
you need this at the head of every file that uses them
*/
/*
This imports the Guardian colour pallette from pasteup as a sass map.
 You can see the palette at support-ui.gutools.co.uk
*/
.is-hidden,
[hidden] {
  display: none !important;
}

.show {
  display: block !important;
}

.component-base-rows--normal > * + * {
  margin-top: 12px;
}

.component-base-rows--small > * + * {
  margin-top: 6px;
}

.component-base-rows--large > * + * {
  margin-top: 24px;
}
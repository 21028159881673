/*
Core page styles such as CSS reset, core helper classes, etc,
Import this once per page.
*/
/*
Mixins, functions & helpers
you need this at the head of every file that uses them
*/
/*
This imports the Guardian colour pallette from pasteup as a sass map.
 You can see the palette at support-ui.gutools.co.uk
*/
.is-hidden,
[hidden] {
  display: none !important;
}

.show {
  display: block !important;
}

/************* Guardian Headline *************/
@font-face {
  font-family: "GH Guardian Headline";
  src: url("https://assets.guim.co.uk/static/frontend/fonts/guardian-headline/noalts-not-hinted/GHGuardianHeadline-Light.woff2") format("woff2"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-headline/latin1-not-hinted/GHGuardianHeadline-Light.woff") format("woff"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-headline/latin1-not-hinted/GHGuardianHeadline-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GH Guardian Headline";
  src: url("https://assets.guim.co.uk/static/frontend/fonts/guardian-headline/noalts-not-hinted/GHGuardianHeadline-LightItalic.woff2") format("woff2"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-headline/latin1-not-hinted/GHGuardianHeadline-LightItalic.woff") format("woff"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-headline/latin1-not-hinted/GHGuardianHeadline-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "GH Guardian Headline";
  src: url("https://assets.guim.co.uk/static/frontend/fonts/guardian-headline/noalts-not-hinted/GHGuardianHeadline-Medium.woff2") format("woff2"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-headline/noalts-not-hinted/GHGuardianHeadline-Medium.woff") format("woff"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-headline/latin1-not-hinted/GHGuardianHeadline-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GH Guardian Headline";
  src: url("https://assets.guim.co.uk/static/frontend/fonts/guardian-headline/noalts-not-hinted/GHGuardianHeadline-MediumItalic.woff2") format("woff2"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-headline/latin1-not-hinted/GHGuardianHeadline-MediumItalic.woff") format("woff"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-headline/latin1-not-hinted/GHGuardianHeadline-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "GH Guardian Headline";
  src: url("https://assets.guim.co.uk/static/frontend/fonts/guardian-headline/noalts-not-hinted/GHGuardianHeadline-Bold.woff2") format("woff2"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-headline/latin1-not-hinted/GHGuardianHeadline-Bold.woff") format("woff"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-headline/latin1-not-hinted/GHGuardianHeadline-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/************* Guardian Text Egyptian *************/
@font-face {
  font-family: "GuardianTextEgyptian";
  src: url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textegyptian/noalts-not-hinted/GuardianTextEgyptian-Regular.woff2") format("woff2"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textegyptian/noalts-not-hinted/GuardianTextEgyptian-Regular.woff") format("woff"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textegyptian/latin1-not-hinted/GuardianTextEgyptian-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GuardianTextEgyptian";
  src: url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textegyptian/noalts-not-hinted/GuardianTextEgyptian-RegularItalic.woff2") format("woff2"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textegyptian/latin1-not-hinted/GuardianTextEgyptian-RegularItalic.woff") format("woff"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textegyptian/latin1-not-hinted/GuardianTextEgyptian-RegularItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "GuardianTextEgyptian";
  src: url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textegyptian/noalts-not-hinted/GuardianTextEgyptian-Bold.woff2") format("woff2"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textegyptian/latin1-not-hinted/GuardianTextEgyptian-Bold.woff") format("woff"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textegyptian/latin1-not-hinted/GuardianTextEgyptian-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GuardianTextEgyptian";
  src: url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textegyptian/noalts-not-hinted/GuardianTextEgyptian-BoldItalic.woff2") format("woff2"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textegyptian/latin1-not-hinted/GuardianTextEgyptian-BoldItalic.woff") format("woff"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textegyptian/latin1-not-hinted/GuardianTextEgyptian-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
/************* Guardian Text Sans *************/
@font-face {
  font-family: "GuardianTextSans";
  src: url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textsans/noalts-not-hinted/GuardianTextSans-Regular.woff2") format("woff2"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textsans/noalts-not-hinted/GuardianTextSans-Regular.woff") format("woff"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textsans/latin1-not-hinted/GuardianTextSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GuardianTextSans";
  src: url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textsans/noalts-not-hinted/GuardianTextSans-RegularItalic.woff2") format("woff2"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textsans/latin1-not-hinted/GuardianTextSans-RegularItalic.woff") format("woff"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textsans/latin1-not-hinted/GuardianTextSans-RegularItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "GuardianTextSans";
  src: url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textsans/noalts-not-hinted/GuardianTextSans-Bold.woff2") format("woff2"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textsans/latin1-not-hinted/GuardianTextSans-Bold.woff") format("woff"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textsans/latin1-not-hinted/GuardianTextSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GuardianTextSans";
  src: url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textsans/noalts-not-hinted/GuardianTextSans-BoldItalic.woff2") format("woff2"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textsans/latin1-not-hinted/GuardianTextSans-BoldItalic.woff") format("woff"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-textsans/latin1-not-hinted/GuardianTextSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
/************* Guardian Titlepiece *************/
@font-face {
  font-family: "GT Guardian Titlepiece";
  /*
  This is a design flourish and needs the full charset to work as intended.
  This makes the file too big in ttf (80kb vs 27kb).
  Only serve this to woff*-compatible devices.
  */
  src: url("https://assets.guim.co.uk/static/frontend/fonts/guardian-titlepiece/full-not-hinted/GTGuardianTitlepiece-Bold.woff2") format("woff2"), url("https://assets.guim.co.uk/static/frontend/fonts/guardian-titlepiece/full-not-hinted/GTGuardianTitlepiece-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  background: transparent;
}

/*
Standard sizes and margins for the content and headers.
*/
.gu-content-margin, .component-page-section__content, .component-squares-introduction__content {
  box-sizing: border-box;
  padding: 0 10px;
}
@media (min-width: 30em) {
  .gu-content-margin, .component-page-section__content, .component-squares-introduction__content {
    width: 480px;
    margin: 0 auto;
    padding: 0 20px;
  }
}
@media (min-width: 41.25em) {
  .gu-content-margin, .component-page-section__content, .component-squares-introduction__content {
    width: 660px;
    margin: 0 auto;
    padding: 0 auto;
  }
}
@media (min-width: 46.25em) {
  .gu-content-margin, .component-page-section__content, .component-squares-introduction__content {
    width: 740px;
    padding: 0 20px;
  }
}
@media (min-width: 61.25em) {
  .gu-content-margin, .component-page-section__content, .component-squares-introduction__content {
    width: 980px;
    padding: 0 20px 0 40px;
  }
}
@media (min-width: 71.25em) {
  .gu-content-margin, .component-page-section__content, .component-squares-introduction__content {
    width: 1140px;
    padding: 0 30px;
  }
}
@media (min-width: 81.25em) {
  .gu-content-margin, .component-page-section__content, .component-squares-introduction__content {
    width: 1300px;
  }
}

@supports (display: flex) {
  .gu-content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

@supports (display: flex) {
  .gu-content__main {
    flex: 1 0 auto;
  }
}

/*
Type defaults
*/
html,
dialog {
  font-family: "GuardianTextEgyptian", Georgia, serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  line-height: 1.5;
}

strong {
  font-weight: bolder;
}

/*
Assistive technologies guidance (non-visible)
*/
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px !important;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}

/*
Disable forms pre hydration
*/
[data-not-hydrated] input,
[data-not-hydrated] button {
  pointer-events: none;
}

.component-veggie-burger-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 42px;
  width: 42px;
  margin: 0;
  padding: 0;
  background: #ffe500;
  border-radius: 100%;
  border: 0;
  cursor: pointer;
  position: relative;
}
.component-veggie-burger-button > svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.component-header-mobile-menu {
  background: #052962;
  min-height: 100%;
  min-height: 100vh;
  max-width: 95vw;
  position: relative;
  box-shadow: 3px 0 16px rgba(0, 0, 0, 0.4);
}
.component-header-mobile-menu .component-veggie-burger-button {
  position: absolute;
  top: 42px;
  right: -21px;
}
.component-header-mobile-menu .component-header-mobile-menu__scroll {
  height: 100%;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  padding: 6px 0 24px;
}

.component-header-links--mobile {
  display: block;
  clear: both;
}
.component-header-links--mobile .component-header-links__ul {
  list-style: none;
}
.component-header-links--mobile .component-header-links__link {
  font-size: 24px;
  line-height: 28px;
  font-family: "GH Guardian Headline", Georgia, serif;
  font-weight: 900;
  line-height: 0.9;
  color: #ffffff;
  text-decoration: none;
  display: block;
  padding: 6px 20px 18px 0;
  overflow: hidden;
  position: relative;
  box-shadow: inset 0 0 0 #ffe500;
  transition: 0.2s linear;
}
.component-header-links--mobile .component-header-links__link:hover, .component-header-links--mobile .component-header-links__link:focus {
  text-decoration: underline;
}
.component-header-links--mobile .component-header-links__li {
  margin-left: 50px;
  border-top: 1px solid #506991;
}
.component-header-links--mobile .component-header-links__li:first-child {
  border-top: none;
}

.component-header-mobile-menu__utility {
  margin-left: 50px;
  border-top: 1px solid #506991;
  padding: 6px 0;
  margin-top: 12px;
}
.component-header-mobile-menu__utility .component-country-group-switcher .component-select-input__select {
  font-size: 16px;
  line-height: 22px;
}

.component-header,
.component-header * {
  box-sizing: content-box;
}

.component-header {
  background-color: #052962;
  display: flex;
  align-items: center;
  justify-content: stretch;
  overflow: hidden;
}
.component-header .component-dialog {
  align-items: stretch;
  justify-content: flex-start;
}
.component-header .svg-guardian-logo {
  display: block;
  float: right;
  margin-top: 5px;
  margin-bottom: 18px;
  height: 48px;
  width: auto;
}
@media (min-width: 30em) {
  .component-header .svg-guardian-logo {
    height: 56px;
  }
}
@media (min-width: 46.25em) {
  .component-header .svg-guardian-logo {
    height: 72px;
  }
}
@media (min-width: 61.25em) {
  .component-header .svg-guardian-logo {
    height: 95px;
  }
}

.component-header--padlock {
  margin-right: 6.6666666667px;
  display: inline-block;
  margin-top: 2px;
}

.component-header-mobile-menu-toggler {
  display: none;
}
@media (max-width: 46.24em) {
  .component-header-mobile-menu-toggler {
    display: block;
  }
}
[data-not-hydrated] .component-header-mobile-menu-toggler {
  display: none;
}
.component-header-mobile-menu-toggler > .component-veggie-burger-button {
  margin-left: 5px;
  /*aligned with the logo*/
  margin-top: 22px;
}
@media (min-width: 30em) {
  .component-header-mobile-menu-toggler > .component-veggie-burger-button {
    margin-top: 28px;
  }
}
@media (min-width: 46.25em) {
  .component-header-mobile-menu-toggler > .component-veggie-burger-button {
    margin-top: 40px;
  }
}
.component-header-mobile-menu-toggler .component-dialog--open .component-header-mobile-menu {
  animation: open-menu 0.4s cubic-bezier(0.23, 1, 0.32, 1) both;
}

@keyframes open-menu {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.component-header__wrapper {
  width: 100%;
  margin: auto;
  padding: 0 10px;
  position: relative;
  flex: 1 1 auto;
}
@media (min-width: 46.25em) {
  .component-header__wrapper {
    max-width: 940px;
  }
}
@media (min-width: 71.25em) {
  .component-header__wrapper {
    max-width: 1100px;
  }
}
@media (min-width: 81.25em) {
  .component-header__wrapper {
    max-width: 1260px;
  }
}

.component-header__row {
  display: flex;
  width: 100%;
}
.component-header__row > .component-header-topnav {
  flex: 1 1 auto;
}
.component-header__row > .component-header-links {
  flex: 1 1 auto;
}

.component-header-topnav {
  display: flex;
  justify-content: space-between;
}

.component-header-topnav__utility {
  padding-top: 6px;
}
@media (max-width: 46.24em) {
  .component-header-topnav__utility .component-country-group-switcher {
    display: none;
  }
}

@media (min-width: 46.25em) {
  .component-header-topnav-logo {
    width: 380px;
    background-color: #052962;
    float: right;
  }
  .component-header--one-row-from-tablet .component-header-topnav-logo {
    border-left: 1px solid #506991;
    z-index: 10;
    position: relative;
  }
}
@media (min-width: 71.25em) {
  .component-header--one-row-from-leftCol .component-header-topnav-logo {
    border-left: 1px solid #506991;
    z-index: 10;
    position: relative;
  }
}
@media (min-width: 81.25em) {
  .component-header-topnav-logo {
    padding-right: 80px;
  }
}

.component-header-topnav-logo__graun {
  float: right;
  height: 100%;
  flex: 0 1 auto;
}

.component-header-links--desktop {
  display: flex;
  justify-content: flex-start;
  background-color: #052962;
  border-top: 1px solid #506991;
  z-index: 9;
  white-space: nowrap;
  margin: 0 -40px 0 0;
  box-sizing: border-box;
}
@media (max-width: 46.24em) {
  .component-header-links--desktop {
    display: none;
  }
}
@media (min-width: 980px) {
  .component-header-links--desktop {
    margin: 0 -20px;
    border-left: 1px solid #506991;
    border-right: 1px solid #506991;
  }
}
@media (min-width: 46.25em) {
  .component-header--one-row-from-tablet .component-header-links--desktop {
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 380px;
  }
}
@media (min-width: 71.25em) {
  .component-header--one-row-from-leftCol .component-header-links--desktop {
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 380px;
  }
}
.component-header-links--desktop .component-header-links__ul {
  list-style: none;
  display: inline-flex;
  width: auto;
}
.component-header-links--desktop .component-header-links__ul > * {
  flex: 0 0 auto;
  display: block;
}
.component-header-links--desktop .component-header-links__link {
  font-size: 20px;
  line-height: 24px;
  font-family: "GH Guardian Headline", Georgia, serif;
  font-weight: 900;
  color: #ffffff;
  text-decoration: none;
  display: block;
  height: 42px;
  padding: 6.8571428571px 30px 8px 6.6666666667px;
  overflow: hidden;
  position: relative;
  box-shadow: inset 0 0 0 #ffe500;
  transition: 0.3s ease-in-out;
  box-sizing: border-box;
}
.component-header-links--desktop .component-header-links__link:hover {
  box-shadow: inset 0 4px 0 #ffe500;
}
.component-header-links--desktop .component-header-links__link:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 14px;
  top: 0;
  width: 1px;
  background-color: #506991;
}
.component-header-links--desktop .component-header-links__li--active .component-header-links__link {
  box-shadow: inset 0 4px 0 #ffe500;
}
.component-header-links--desktop .component-header-links__li:first-child > .component-header-links__link {
  padding-left: 20px;
}
.component-header-links--desktop .component-header-links__li:last-child > .component-header-links__link:after {
  display: none;
}

.component-header--display-checkout .component-header__wrapper {
  max-width: 50rem;
}
@media (min-width: 46.25em) and (max-width: 61.24em) {
  .component-header--display-checkout .component-header__wrapper {
    max-width: 100%;
    padding: 0;
    margin: 0;
  }
}
@media (min-width: 71.25em) {
  .component-header--display-checkout .component-header__wrapper {
    max-width: 60rem;
  }
}
@media (max-width: 46.24em) {
  .component-header--display-checkout .component-header__wrapper .component-header-topnav {
    justify-content: space-between;
  }
}
@media (min-width: 46.25em) and (max-width: 61.24em) {
  .component-header--display-checkout .component-header__wrapper .component-header-topnav {
    justify-content: flex-end;
  }
}
.component-header--display-checkout .component-header__wrapper .component-header-topnav .component-header-topnav__utility {
  display: none;
}
.component-header--display-checkout .component-header__wrapper .component-header-topnav .component-header-topnav__checkout {
  display: flex;
  flex: 0 0 490px;
  color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  font-family: "GuardianTextSans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
}
@media (max-width: 46.24em) {
  .component-header--display-checkout .component-header__wrapper .component-header-topnav .component-header-topnav__checkout {
    flex: none;
    border-right: none;
  }
}
.component-header--display-checkout .component-header__wrapper .component-header-topnav .component-header-topnav__checkout .component-header-topnav--checkout-text {
  border-top: 1px solid #506991;
  border-left: 1px solid #506991;
  padding: 12px 20px;
  width: 100%;
  align-self: flex-end;
}
.component-header--display-checkout .component-header__wrapper .component-header-topnav .component-header-topnav__checkout .component-header-topnav--checkout-text :nth-child(2) {
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 46.24em) {
  .component-header--display-checkout .component-header__wrapper .component-header-topnav .component-header-topnav__checkout .component-header-topnav--checkout-text {
    border-left: none;
    border-top: none;
    padding-left: 5px;
  }
}
@media (min-width: 46.25em) and (max-width: 61.24em) {
  .component-header--display-checkout .component-header__wrapper .component-header-topnav .component-header-topnav__checkout {
    display: none;
  }
}
@media (min-width: 71.25em) {
  .component-header--display-checkout .component-header__wrapper .component-header-topnav .component-header-topnav__checkout {
    flex: 0 0 650px;
  }
}
.component-header--display-checkout .component-header__wrapper .component-header-topnav-logo {
  flex: 0 1 auto;
}
@media (min-width: 61.25em) {
  .component-header--display-checkout .component-header__wrapper .component-header-topnav-logo {
    margin-right: -30px;
  }
}
.component-header--display-checkout .component-header-topnav-logo {
  padding-right: 10px;
  padding-top: 6px;
}
@media (min-width: 61.25em) {
  .component-header--display-checkout .component-header-topnav-logo {
    border-left: 1px solid #506991;
  }
}

.component-header-checkout--row {
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  font-family: "GuardianTextSans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  padding: 12px;
  border-top: 1px solid #506991;
}
@media (min-width: 61.25em) {
  .component-header-checkout--row {
    display: none;
  }
}
@media (max-width: 46.24em) {
  .component-header-checkout--row {
    display: none;
  }
}

.component-header-links__li.component-header-links__li--show-on-tablet {
  display: block;
}
@media (min-width: 884px) {
  .component-header-links__li.component-header-links__li--show-on-tablet {
    display: none;
  }
}

.test-user-banner {
  background-color: red;
  width: 100%;
}

.component-footer {
  font-size: 14px;
  line-height: 18px;
  font-family: "GuardianTextSans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 700;
  font-weight: 400;
  color: #ffffff;
}

.component-content.component-content--feature.component-content--faqs .component-content__content {
  padding-top: 6px;
}

.component-footer a {
  text-decoration: underline;
  text-decoration-color: rgba(255, 255, 255, 0.86);
  color: inherit;
}
.component-footer a:hover {
  text-decoration: underline;
}

.component-footer__copyright {
  font-size: 12px;
}

.component-squares-introduction {
  background-color: #f6f6f6;
  color: #121212;
  position: relative;
  overflow: hidden;
  font-family: "GH Guardian Headline", Georgia, serif;
  font-weight: bold;
  font-size: 28px;
  line-height: 30px;
}
@media (min-width: 23.4375em) {
  .component-squares-introduction {
    font-size: 30px;
    line-height: 32px;
  }
}
@media (min-width: 41.25em) {
  .component-squares-introduction {
    font-size: 46px;
    line-height: 50px;
  }
}
@media (min-width: 61.25em) {
  .component-squares-introduction {
    font-size: 48px;
    line-height: 52px;
  }
}
.component-squares-introduction .svg-squares-hero-desktop {
  position: absolute;
  top: 0;
  min-height: 100%;
  display: none;
}
@media (min-width: 61.25em) {
  .component-squares-introduction .svg-squares-hero-desktop {
    display: inline;
  }
}
.component-squares-introduction .svg-squares-hero-tablet {
  position: absolute;
  top: 0;
  min-height: 100%;
  display: none;
}
@media (min-width: 30em) and (max-width: 61.24em) {
  .component-squares-introduction .svg-squares-hero-tablet {
    display: inline;
  }
}
.component-squares-introduction .svg-squares-hero-mobile {
  position: absolute;
  top: 0;
  min-height: 100%;
  display: none;
  width: 100%;
}
@media (max-width: 29.99em) {
  .component-squares-introduction .svg-squares-hero-mobile {
    display: inline;
  }
}
@media (min-width: 61.25em) {
  .component-squares-introduction .component-highlights {
    padding-left: 20px;
    margin-left: 20%;
  }
}

.component-squares-introduction__content {
  padding-top: 12px;
  padding-bottom: 220px;
}
@media (min-width: 30em) {
  .component-squares-introduction__content {
    padding-bottom: 170px;
  }
}
@media (min-width: 61.25em) {
  .component-squares-introduction__content {
    padding-bottom: 10%;
  }
}

@media (min-width: 61.25em) {
  .component-squares-introduction__heading {
    padding-left: 20px;
    margin-left: 20%;
  }
}

.component-squares-introduction__heading-line {
  display: block;
}

.component-highlights__line {
  display: block;
}

.component-highlights__highlight {
  display: inline-block;
  vertical-align: top;
  padding: 4px;
  background-color: #ffe500;
}

.component-highlights__highlight--compact {
  font-size: 16px;
  line-height: 18px;
}
@media (min-width: 41.25em) {
  .component-highlights__highlight--compact {
    font-size: 18px;
    line-height: 20px;
  }
}
@media (min-width: 61.25em) {
  .component-highlights__highlight--compact {
    font-size: 18px;
  }
}
@media (min-width: 71.25em) {
  .component-highlights__highlight--compact {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (min-width: 61.25em) {
  .component-page-section__header {
    display: inline-block;
    vertical-align: top;
    width: 20%;
  }
}

.component-page-section__body {
  box-sizing: border-box;
}
.component-page-section__body .component-text {
  margin: 36px 0;
}
@media (min-width: 61.25em) {
  .component-page-section__body {
    display: inline-block;
    width: 80%;
  }
}

.component-cta-link {
  text-align: left;
  background-color: #121212;
  transition: background-color 0.3s;
  border: none;
  border-radius: 600px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: "GuardianTextSans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 14px;
  height: 42px;
  line-height: 42px;
  padding: 0 20px;
  position: relative;
  text-decoration: none;
  font-weight: 700;
}
.component-cta-link:hover {
  background-color: rgb(5.25, 5.25, 5.25);
}
.component-cta-link svg {
  height: 16px;
  position: absolute;
  left: calc(100% - 34px);
  top: 14px;
  fill: #fff;
}

.component-cta-link--border {
  border: 1px solid;
  height: 42px;
  line-height: 42px;
}
.component-cta-link--border svg {
  top: 13px;
  left: calc(100% - 33px);
}

.component-left-margin-section {
  display: flex;
}
@media (min-width: 46.25em) {
  .component-left-margin-section:before {
    content: "";
    flex-basis: 0;
    flex-grow: 1;
    display: block;
  }
}

.component-left-margin-section--blue {
  color: #ffffff;
}
.component-left-margin-section--blue .component-left-margin-section__content {
  background: #005689;
}

.component-left-margin-section__content {
  flex-grow: 1;
  flex-basis: 720px;
}
@media (min-width: 61.25em) {
  .component-left-margin-section__content {
    flex-basis: 800px;
  }
}
@media (min-width: 71.25em) {
  .component-left-margin-section__content {
    flex-basis: 960px;
  }
}

.component-content .component-left-margin-section__content {
  max-width: 100%;
  position: relative;
}
@media (min-width: 46.25em) {
  .component-content .component-left-margin-section__content {
    border-left: 1px solid #dcdcdc;
  }
}

.component-content--overflow-hidden .component-left-margin-section__content {
  overflow: hidden;
}

.component-content--white,
.component-content--white .component-content-bg {
  background-color: #ffffff;
}
.component-content--white,
.component-content--white .component-content-bg, .component-content--white .component-left-margin-section__content, .component-content--white .component-product-page-features .component-product-page-features__item {
  border-color: #dcdcdc;
}
.component-content--white .component-content__divider__line {
  background-image: repeating-linear-gradient(to bottom, #dcdcdc, #dcdcdc 1px, transparent 1px, transparent 4px);
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 1px 13px;
  height: 13px;
}
.component-content--white.component-content--force-border .component-left-margin-section__content, .component-content--white + .component-content--white .component-left-margin-section__content {
  border-top: 1px solid #dcdcdc;
}
.component-content--white.component-content--no-border .component-left-margin-section__content {
  border-top: 0 !important;
}

.component-content--grey,
.component-content--grey .component-content-bg {
  background-color: #f6f6f6;
}
.component-content--grey,
.component-content--grey .component-content-bg, .component-content--grey .component-left-margin-section__content, .component-content--grey .component-product-page-features .component-product-page-features__item {
  border-color: transparent;
}
.component-content--grey .component-content__divider__line {
  background-image: repeating-linear-gradient(to bottom, transparent, transparent 1px, transparent 1px, transparent 4px);
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 1px 13px;
  height: 13px;
}
.component-content--grey.component-content--force-border .component-left-margin-section__content, .component-content--grey + .component-content--grey .component-left-margin-section__content {
  border-top: 1px solid transparent;
}
.component-content--grey.component-content--no-border .component-left-margin-section__content {
  border-top: 0 !important;
}

.component-content--feature {
  color: #ffffff;
}
.component-content--feature,
.component-content--feature .component-content-bg {
  background-color: #052962;
}
.component-content--feature,
.component-content--feature .component-content-bg, .component-content--feature .component-left-margin-section__content, .component-content--feature .component-product-page-features .component-product-page-features__item {
  border-color: #506991;
}
.component-content--feature .component-content__divider__line {
  background-image: repeating-linear-gradient(to bottom, #506991, #506991 1px, transparent 1px, transparent 4px);
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 1px 13px;
  height: 13px;
}
.component-content--feature.component-content--force-border .component-left-margin-section__content, .component-content--feature + .component-content--feature .component-left-margin-section__content {
  border-top: 1px solid #506991;
}
.component-content--feature.component-content--no-border .component-left-margin-section__content {
  border-top: 0 !important;
}

.component-content--highlight {
  color: #121212;
}
.component-content--highlight,
.component-content--highlight .component-content-bg {
  background-color: #ffe500;
}
.component-content--highlight,
.component-content--highlight .component-content-bg, .component-content--highlight .component-left-margin-section__content, .component-content--highlight .component-product-page-features .component-product-page-features__item {
  border-color: #506991;
}
.component-content--highlight .component-content__divider__line {
  background-image: repeating-linear-gradient(to bottom, #506991, #506991 1px, transparent 1px, transparent 4px);
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 1px 13px;
  height: 13px;
}
.component-content--highlight.component-content--force-border .component-left-margin-section__content, .component-content--highlight + .component-content--highlight .component-left-margin-section__content {
  border-top: 1px solid #506991;
}
.component-content--highlight.component-content--no-border .component-left-margin-section__content {
  border-top: 0 !important;
}

.component-content--dark {
  color: #ffffff;
}
.component-content--dark,
.component-content--dark .component-content-bg {
  background-color: #333333;
}
.component-content--dark,
.component-content--dark .component-content-bg, .component-content--dark .component-left-margin-section__content, .component-content--dark .component-product-page-features .component-product-page-features__item {
  border-color: #767676;
}
.component-content--dark .component-content__divider__line {
  background-image: repeating-linear-gradient(to bottom, #767676, #767676 1px, transparent 1px, transparent 4px);
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 1px 13px;
  height: 13px;
}
.component-content--dark.component-content--force-border .component-left-margin-section__content, .component-content--dark + .component-content--dark .component-left-margin-section__content {
  border-top: 1px solid #767676;
}
.component-content--dark.component-content--no-border .component-left-margin-section__content {
  border-top: 0 !important;
}

.component-content--higher {
  position: relative;
  z-index: 10;
}

.component-content__content,
.component-content__content--grey {
  padding: 6px 10px 0;
}

.component-footer .component-content__content,
.component-footer .component-content__content--grey {
  padding: 10px;
}

.component-content__content {
  max-width: 700px;
}
@media (min-width: 61.25em) {
  .component-content__content {
    padding-top: 24px;
  }
}

@media (min-width: 61.25em) {
  .component-content.component-content--white.component-content--overflow-hidden.component-content--force-border .component-text {
    max-width: 460px;
  }
}

.component-content__content--grey {
  background-color: #f6f6f6;
}

.component-content__outset {
  margin-left: -10px;
  margin-right: -10px;
}

.component-content__divider {
  margin-left: -10px;
  margin-right: -10px;
  height: 20px;
  margin-bottom: 3.96px;
}

.component-content__divider--small {
  max-width: 540px;
  height: 1px;
  overflow: hidden;
  position: relative;
}
@media (min-width: 61.25em) {
  .component-content__divider--small {
    max-width: 620px;
  }
}

.component-content__divider__line {
  position: absolute;
  left: 0;
  right: 0;
  border: 0;
  margin: 0;
}

.component-content {
  /*
   cancel out initial padding
   */
}
.component-content .component-content__narrowContent,
.component-content .component-text,
.component-content .component-product-page-plan-form,
.component-content .component-product-page-info-chip {
  margin-bottom: 31.9992px;
}
.component-content .component-product-page-info-chip + .component-product-page-info-chip {
  margin-top: -12px;
}
.component-content .component-content__divider:last-child {
  margin-bottom: -8px;
  margin-top: 8px;
}
.component-content .component-content__content > .component-content__divider:first-child,
.component-content .component-product-page-tabs {
  margin-top: -7px;
}

.component-content__image {
  position: absolute;
  max-width: 460px;
  top: -24px;
  /*
   If you are implementing an image in a page and you want
   it to look just a teeny tiny bit bigger or smaller or plain move it,
   try to avoid overriding `left` to do fine positioning and
   instead use transforms and max-width.

   `left` here is built to put images to the right of the max grid width.
   This width might change in the future and ideally tweaking these global
   values should not break any images on any page.

   If your image is not grid aligned feel free to override these values
   but also consider if using this image prop is the best approach. You can
   put your image as a normal child of `ContentBlock`
   */
}
@media (min-width: 46.25em) {
  .component-content__image {
    left: 580px;
  }
}
@media (min-width: 61.25em) {
  .component-content__image {
    left: 500px;
  }
}
@media (min-width: 81.25em) {
  .component-content__image {
    left: 540px;
  }
}
.component-content__image .component-grid-image {
  display: none;
}
@media (min-width: 61.25em) {
  .component-content__image .component-grid-image {
    display: block;
    width: 100%;
  }
}

#error-404-page .component-page-section--ctas:before,
#error-500-page .component-page-section--ctas:before,
#down-for-maintenance-page .component-page-section--ctas:before {
  background-image: repeating-linear-gradient(to bottom, #dcdcdc, #dcdcdc 1px, transparent 1px, transparent 4px);
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 1px 13px;
  height: 13px;
  background-color: #fff;
  display: block;
  content: "";
}
@media (min-width: 61.25em) {
  #error-404-page .component-page-section--ctas .component-page-section__header,
  #error-500-page .component-page-section--ctas .component-page-section__header,
  #down-for-maintenance-page .component-page-section--ctas .component-page-section__header {
    position: relative;
  }
  #error-404-page .component-page-section--ctas .component-page-section__header:after,
  #error-500-page .component-page-section--ctas .component-page-section__header:after,
  #down-for-maintenance-page .component-page-section--ctas .component-page-section__header:after {
    border-right: 1px solid #dcdcdc;
    content: "";
    display: inline-block;
    height: 36px;
    position: absolute;
    top: 0;
    right: 0;
  }
}
#error-404-page .component-page-section--ctas .component-page-section__body,
#error-500-page .component-page-section--ctas .component-page-section__body,
#down-for-maintenance-page .component-page-section--ctas .component-page-section__body {
  padding-top: 6px;
  padding-bottom: 24px;
}
@media (min-width: 61.25em) {
  #error-404-page .component-page-section--ctas .component-page-section__body,
  #error-500-page .component-page-section--ctas .component-page-section__body,
  #down-for-maintenance-page .component-page-section--ctas .component-page-section__body {
    padding-left: 20px;
  }
}
#error-404-page .component-page-section--ctas .svg-arrow-right-straight,
#error-500-page .component-page-section--ctas .svg-arrow-right-straight,
#down-for-maintenance-page .component-page-section--ctas .svg-arrow-right-straight {
  fill: #121212;
}
#error-404-page .error-copy,
#error-500-page .error-copy,
#down-for-maintenance-page .error-copy {
  margin-bottom: 24px;
}
@media (min-width: 41.25em) {
  #error-404-page .error-copy,
  #error-500-page .error-copy,
  #down-for-maintenance-page .error-copy {
    width: 500px;
  }
}
#error-404-page .error-copy__link,
#error-500-page .error-copy__link,
#down-for-maintenance-page .error-copy__link {
  color: inherit;
}
#error-404-page .component-cta-link--support-the-guardian,
#error-500-page .component-cta-link--support-the-guardian,
#down-for-maintenance-page .component-cta-link--support-the-guardian {
  background-color: #ffe500;
  color: #121212;
}
#error-404-page .component-cta-link--support-the-guardian:hover,
#error-500-page .component-cta-link--support-the-guardian:hover,
#down-for-maintenance-page .component-cta-link--support-the-guardian:hover {
  background-color: rgb(229.5, 206.1, 0);
}
@media (min-width: 41.25em) {
  #error-404-page .component-cta-link--support-the-guardian,
  #error-500-page .component-cta-link--support-the-guardian,
  #down-for-maintenance-page .component-cta-link--support-the-guardian {
    width: 252px;
  }
}
#error-404-page .component-cta-link--guardian-home-page,
#error-500-page .component-cta-link--guardian-home-page,
#down-for-maintenance-page .component-cta-link--guardian-home-page {
  background-color: #fff;
  margin-top: 12px;
  color: #121212;
  border-color: #121212;
}
#error-404-page .component-cta-link--guardian-home-page:hover,
#error-500-page .component-cta-link--guardian-home-page:hover,
#down-for-maintenance-page .component-cta-link--guardian-home-page:hover {
  color: rgb(5.25, 5.25, 5.25);
  border-color: rgb(5.25, 5.25, 5.25);
}
@media (min-width: 41.25em) {
  #error-404-page .component-cta-link--guardian-home-page,
  #error-500-page .component-cta-link--guardian-home-page,
  #down-for-maintenance-page .component-cta-link--guardian-home-page {
    width: 250px;
  }
}
/*
Mixins, functions & helpers
you need this at the head of every file that uses them
*/
/*
This imports the Guardian colour pallette from pasteup as a sass map.
 You can see the palette at support-ui.gutools.co.uk
*/
.is-hidden,
[hidden] {
  display: none !important;
}

.show {
  display: block !important;
}

/*
Standard sizes and margins for the content and headers.
*/
.gu-content-margin, .component-page-section__content {
  box-sizing: border-box;
  padding: 0 10px;
}
@media (min-width: 30em) {
  .gu-content-margin, .component-page-section__content {
    width: 480px;
    margin: 0 auto;
    padding: 0 20px;
  }
}
@media (min-width: 41.25em) {
  .gu-content-margin, .component-page-section__content {
    width: 660px;
    margin: 0 auto;
    padding: 0 auto;
  }
}
@media (min-width: 46.25em) {
  .gu-content-margin, .component-page-section__content {
    width: 740px;
    padding: 0 20px;
  }
}
@media (min-width: 61.25em) {
  .gu-content-margin, .component-page-section__content {
    width: 980px;
    padding: 0 20px 0 40px;
  }
}
@media (min-width: 71.25em) {
  .gu-content-margin, .component-page-section__content {
    width: 1140px;
    padding: 0 30px;
  }
}
@media (min-width: 81.25em) {
  .gu-content-margin, .component-page-section__content {
    width: 1300px;
  }
}

@supports (display: flex) {
  .gu-content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

@supports (display: flex) {
  .gu-content__main {
    flex: 1 0 auto;
  }
}

/*
Type defaults
*/
html,
dialog {
  font-family: "GuardianTextEgyptian", Georgia, serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  line-height: 1.5;
}

strong {
  font-weight: bolder;
}

/*
Assistive technologies guidance (non-visible)
*/
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px !important;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}

/*
Disable forms pre hydration
*/
[data-not-hydrated] input,
[data-not-hydrated] button {
  pointer-events: none;
}

@media (min-width: 61.25em) {
  .component-page-section__header {
    display: inline-block;
    vertical-align: top;
    width: 20%;
  }
}

.component-page-section__body {
  box-sizing: border-box;
}
.component-page-section__body .component-text {
  margin: 36px 0;
}
@media (min-width: 61.25em) {
  .component-page-section__body {
    display: inline-block;
    width: 80%;
  }
}